@font-face {
  font-family: Almarai;
  src: url(./assets/fonts/Almarai-Regular.ttf);
}
@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: beIN;
  src: url(./assets/fonts/beIN-normal.ttf);
}

@font-face {
  font-family: bukra;
  src: url(./assets/fonts/bukra.ttf);
}
:root {
  --main-color: #196ec2;
  --main-font: 'Almarai';
  --second-font: 'Montserrat';
  --third-font: 'beIN';
  --four-font: 'bukra';
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,
body {
  max-width: 1900px;
  margin: auto;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body.blur {
  height: 100vh;
  overflow: hidden;
}

.App {
  width: 100vw;
  /* overflow: hidden; */
  min-height: 100vh;

  /* background-image: url('./assets/images/test.jpg');
  background-size: contain; */
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background: linear-gradient(
    to right bottom,
    /* rgb(198 192 192 / 70%),
    rgb(198 192 192 / 30%)  */
      rgba(250, 250, 250, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  z-index: 11;
  backdrop-filter: blur(0.45rem);
  top: 0;
}

/* svg {
  background: gold;
  width: 100vw;
  max-height: 100vh;
}

svg path {
  fill: coral;
  transition: all 0.5s;
}

svg:hover path {
  d: path('M5,5 L50,50 l30,-20z');
  d: 'M5,5 L50,50 l30,-20z';
} */

.wave-top {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

svg path {
  fill: linear-gradient(#0088ff, #004480);
  transition: all 0.5s;
}
